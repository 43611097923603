import github from "./img/github.svg.png";
import javascript from "./img/javascript-js-seeklogo.com.svg";
import cssLogo from "./img/css.png";
import htmlLogo from "./img/html.png";
import jquery from "./img/jquery-1.png";
import wordpress from "./img/WordPress-logotype-simplified.png";
import illust from "./img/adobe-Illustrator-download-free-PNG.png";
import pshop from "./img/ps.png";
import php from "./img/php.png";
import react from "./img/React-icon.svg.png";
import vscode from "./img/Visual_Studio_Code_1.35_icon.svg.png";
import sass from "./img/sass.png";
import responsive from "./img/tablet-phone-and-browser.svg";
import sketch from "./img/Sketch.png";
import bootstrap from "./img/bootstrap.png";
import stackoverflow from "./img/stackoverflow.png";
import contentwriting from "./img/contentwriter.png";


const Skills  = () => {
    return ( 




      <section class="skills-section" id="skills-scroll">
        <h1 class="h1-style">Tools</h1>

        <div class="skill_blocks">
          <div id="blocks" class="js">
            <img src={javascript} alt="The javascript logo." />
            <p>Javascript</p>
          </div>
          <div id="blocks">
            <img src={cssLogo} alt="The CSS logo." />
            <p>CSS3</p>
          </div>
          <div id="blocks">
            <img src={htmlLogo} alt="The logo of HTML."/>
            <p>HTML5</p>
          </div>

          <div id="blocks">
            <img src={react} alt="The React JS logo." />
            <p>React</p>
          </div>

          <div id="blocks">
            <img src={jquery} alt="The jquery logo" />
            <p>jQuery</p>
          </div>

            <div id="blocks">
            <img src={sass} alt="The SASS extension language logo."/>
            <p>Sass</p>
          </div>

            <div id="blocks">
            <img src={bootstrap} class="bootstrap" alt="The bootstrap logo." />
            <p>Bootstrap</p>
          </div>

          {/*
            <div id="blocks">
            <img src={php}  alt="The PHP logo." />
            <p>PHP</p>
          </div>
    */}
          <div id="blocks">
            <img src={wordpress} class="wordpress"  alt="The wordpress logo."/>

            <p>Wordpress</p>
          
          </div>

          <div id="blocks">
            <img src={illust}  alt="The Adobe Illustrator Logo" />
            <p>Adobe</p>
          </div>

          <div id="blocks">
            <img src={pshop}  alt="The Adobe Photoshop Logo"/>
            <p>Photoshop</p>
          </div>

          <div id="blocks">
            <img src={vscode} alt="The Visual Studio Code logo." />
            <p>VsCode</p>
          </div>
          <div id="blocks" class="git">
            <img src={github}  alt="The Github logo."/>
            <p>Github</p>
          </div>

          <div id="blocks">
            <img src={responsive}  alt="The responsive web developentlogo." />
            <p>Responsive</p>
          </div>
          <div id="blocks">
            <img src={sketch} class="sketch"  alt="The sketch software logo." />
            <p>Sketch</p>
          </div>

            <div id="blocks">
            <img src={stackoverflow} class="stackoverflow" alt="The bootstrap logo." />
            <p>Stack Overflow 😬</p>
          </div>
        </div>

      </section>




     );
}
 
export default Skills ;
import audio from "./audio/Stranger_Things _8D.mp3";


  var audioElement = document.getElementById('audio');

  // Check if the audio element is supported
  if (audioElement && typeof audioElement.play === 'function') {
    // Play the audio
    audioElement.play();
  }


const Audio  = () => {

    return (  

      <div>
      
      <audio controls autoPlay muted loop id="audio" alt="An light orange audio player, playing the Stranger Things theme song." >
        <source src={audio} type="audio/mp3" />
        <p>Music player not supported. Music: Stranger Things Theme in 8D</p>
        </audio>
        
        <p id="music-message">Click play and unmute for the full experience.</p>

      

    </div>

    );
}
 
export default Audio;
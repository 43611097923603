import { useState } from "react";


const About = () => {

  const [show, setShow] = useState(false);


    return (  

  

      <div class="about-section" id="about-scroll">
        <h1 class="h1-style">About</h1>

        <div class="summary">

         <p id="quote">"I create visual chemistry with code."</p>

          <p class="hello">Hello! </p>



          <h4>Short Version:</h4>
          <p>
          I am a visually creative front-end web developer that can create
          responsive websites from scratch along with version control experience using git.
          Also with a background of photography and design art that compliments 
          my ability to code with a visual user-friendly experience. 
          </p>
          

            

            <button onClick={()=>setShow(!show)} id="moreBtn">Click for Long Version</button>
         
          
        
            {show?<span id="more">

            
              <p>
           
                I'm Michael – a hungry self-taught web developer with an eye of design
                who desires to grow with a rockstar company while putting out
                visually great work.
              </p>
            
              <p>
                I've always been a creative person but I officially
                learned design from college courses where I learned the
                art of graphic design. Then I discovered web development and loved
                it! -- realizing I could use all of that creativeness in combination
                with code.
            
              </p>

              <p>
                I don't have developmental job experience yet or a cs degree and
                those are good reasons why you may not want to hire me but I believe I have
                the ability to produce great works for your company while communicating
                with empathy and intellectual-simplicity with clients and team.
              </p>

              <p>
                I'm new to the field, so hire me only if you believe I can do the job.
                I put in a lot of work and time to get to the point where I could design
                this portfolio from scratch with React JS, hoping it impresses a hirer.
              </p>

              <p>
                If interested, feel free to contact me and I will do my best to prove it!
            </p> 
            
            </span>:null }

          <a href="#portfolio-scroll"><button>View my work</button></a>
        </div>
      </div>

      


    );
}
 
export default About;
/** @format */

const Footer = () => {

    return (
     
     <footer>
         
         <div class='title' id='footer-scroll'>
             
             <h1 class='h1-style'>Get in Touch</h1>
             
         </div>
         

            <section class='get-in-touch'>
                
                

                    
                    
              
                    
                <div class='contact-form'>
  
                    <form action="https://formsubmit.co/michael.jr.robinson@gmail.com" method="POST" >
                        
    
                    
                            
                            
                            <div class="form-row">
                                
                      
                                <input type="text" name="name" id='name' class='input-text' placeholder="Full Name" required />
        
                                <input type="email" name="email" id='email' class='input-text' placeholder="Email Address" required />
                                
        
                            </div>
                            
                      
                        
    
                        <div class="form-group">
                            
      
                            <textarea placeholder="Your Message" id="message" class="message" name="message" required />
                            
      
                        </div>
                        
    
                        <button type="submit" class='submit-btn'>Submit Form</button>
                        
    
                    </form>
                    
              </div>
                

 
            </section>

            



            <div class='copyright'>
                
                <p>Website 100% Built by</p>
                <p>Michael Robinson®</p>
                <br></br>
                <p>Javascript, CSS Grid, ReactJs, and a touch of creativity.</p>
                
            </div>
            
        </footer>
        
    );
    
};


export default Footer;



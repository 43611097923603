
import video from "./vids/sunglobe.mp4";
import linkedn from "./img/linkedin-black-icon-logo-ECC426C572-seeklogo.com.png";
/*import resume from "./img/resume-logo-png-1-Transparent-Images.png"; */
import git from "./img/github.svg.png";
import Nav from './Nav.js';



const Header = () => {
    return ( 



              <header>

            <video autoPlay muted loop id="video">
            <source src={video} type="video/mp4" />
            </video>


        <Nav />



        <div class="name-feature">
          <p class="first-name">Michael</p>
          <p class="last-name">Robinson</p>
          <p class="last-name-two">Robinson</p>
          <p class="developer">A Web Developer with Cosmic Skill</p>


          <div class="social-media">
            <a href="https://github.com/MRCODES" target="_blank" rel="noreferrer">
              <div class="mygit">
                <img src={git} width="50px" height="50px" alt="The githut logo"/>
              </div>
            </a>

            
            <a href="https://www.linkedin.com/in/sir-mike-robinson"


 target="_blank" rel="noreferrer">
              <div class="linkedin">
                <img src={linkedn} width="50px" height="50px" alt="A linked-in logo" />
              </div>
            </a>
   




            {/*
            <div class="resume">
              <img src={resume} width="50px" height="50px" alt="A paper document or resume." />
            </div>
    */}

          </div>
        </div>
      </header>




     );
}
 
export default Header;
/** @format */

const Portfolio = () => {
  return (
    <section class="portfolio-section" id="portfolio-scroll">
      <h1 class="h1-style">Works</h1>

      <h2>Apps</h2>

      <div class="articles">
        <div id="apps">
          
          <article class="one_box">
            <div id="works-described">
              <h3>Tip App</h3>

              <ul>
                <li>Javascript</li> <br />
                <li>jQuery</li> <br />
                <li>CSS</li> <br />
                <li>Design</li> <br />
              </ul>

              <b>
                <p>Summary</p>
              </b>

              <h5>
                I created "TipCalc," a simple scorekeeping app using JavaScript,
                jQuery, and CSS. It quickly calculates tip amounts and includes
                a reset button for easy clearing of calculations.
                <br></br>
                <br></br>
                This project demonstrates my ability to develop efficient and
                user-friendly applications.
              </h5>
            </div>





            
            {/*TIPS*/}

            <div id="works-btns">
              <a
                href="https://app-tips.netlify.app"
                target="_blank"
                rel="noreferrer noopener"
              >
                
                  Site | {'<>'}
                

              </a>

              <a
                href="https://github.com/MRCODES/Tip-Calculator-by-MR"
                target="_blank"
                rel="noreferrer noopener"
              >
               Code{'<>'}
              </a>
            </div>


          </article>




          {/*SCORE*/}

          <article class="two_box">
            <div id="works-described">
              <h3>Score Keeper</h3>
              <ul>
                <li>React.Js</li>
                <li>CSS</li>
                <li>Logo Design</li>
                <li>Fonts</li>
              </ul>

              <b>
                <p>Summary</p>
              </b>
              <h5>
                I created a simple scorekeeping app personal project I named
                “TipCalc” that makes it simple for users to keep track of scores
                and subtract scores.
                <br></br>
                <br></br>I created a visually appealing logo and implemented
                chalk font to go along with the theme. And to clear the board, I
                created a reset button. Developed using React useState.
              </h5>
            </div>

            <div id="works-btns">
            
                <a
                  href="https://react-score-keeper.netlify.app"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Site | {'<>'}
                </a>
             

            
                <a
                  href="https://github.com/MRCODES/scorekeeperapp"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Code{'<>'}
                </a>
              
            </div>
          </article>

          {/*BMR*/}

          <article class="three_box">
            <div id="works-described">
              <h3>BMR App</h3>

              <ul>
                <li>React.Js</li>
                <li>CSS</li>
                <li>Logo Design</li>
                <li>Fonts</li>
              </ul>

              <b>
                <p>Summary</p>
              </b>
              <br></br>

              <h5>
                Developed a simple app geared toward helping people lose or
                manage their weight to live healthier.
                <br></br>
                <br></br>
                Using React, mathematical formula, and CSS, I was able to create
                a smooth visually appealing app. It has the ability to calculate
                according to gender and has a quick reset to clear data.
              </h5>
            </div>

            <div id="works-btns">
              
                <a
                  href="https://bmr-calc.netlify.app"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Site{" "} | {'<>'}
                </a>
              

              
                <a
                  href="https://github.com/MRCODES/BMR-Calculator"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Code{'<>'}
                </a>
              
            </div>
          </article>

          {/*QUOTES*/}

          <article class="four_box">
            <div id="works-described">
              <h3>Random Quotes API</h3>
              <ul>
                <li>Javascript</li>
                <li>Fetch </li>
                <li>API </li>
                <li>CSS Grid </li>
                <li>Flexbox</li>
              </ul>

              <b>
                <p>Summary</p>
              </b>
              <br></br>

              <h5>
                I created an inspirational quotes app designed for quote
                enthusiasts seeking daily inspiration.
                <br></br>
                <br></br>
                This project showcases my ability to integrate APIs with the
                front-end. The app dynamically fetches quotes from external
                APIs, presenting users with a fresh dose of motivation each day.
              </h5>
            </div>

            <div id="works-btns">
              
                 <a
                  href="https://quote-toggler.netlify.app/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Site | {'<>'}
                </a>
              

              
                <a
                  href="https://github.com/MRCODES/quotes--generator"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Code{'<>'}
                </a>




            </div>





          </article>
        </div>{" "}
        {/*End Apps*/}






        <h2>Websites</h2>
        <div id="websites">
          {/*YOGA*/}

          <article class="five_box">
            <div id="works-described">
              <h3>Yoga Dojo</h3>
              <ul>
                <li>HTML</li>
                <li>CSS</li>
                <li>JavaScript</li>
                <li>JQuery</li>
                <li>Animation</li>
              </ul>
              <b>
                <p>Summary</p>
              </b>

              <br></br>

              <h5>
                I envisioned and developed a yoga studio website from scratch,
                using HTML, CSS, JavaScript, jQuery, and animations.
                <br></br>
                <br></br>
                This project showcases my abilities in web development and
                design, creating a visually appealing and interactive website
                that enhances user experience.
              </h5>
            </div>

            <div id="works-btns">
              
                <a
                  href="https://yoga-dojo.netlify.app"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Site | {'<>'}
                </a>
              

              
                <a
                  href="https://github.com/MRCODES/well-yoga/tree/main/well-yoga-website-live"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Code{'<>'}
                </a>
              
            </div>
          </article>

          {/*COFFEE*/}
          <article class="six_box">
            <div id="works-described">
              <h3>Coffee Website</h3>

              <ul>
                <li>HTML</li>

                <li>CSS</li>

                <li>Javascript</li>

                <li>JQuery</li>
              </ul>

              <b>
                <p>Summary</p>
              </b>

              <br></br>

              <h5>
                I developed a coffee brand website from scratch, utilizing HTML,
                CSS, JavaScript, and jQuery.
                <br></br>
                <br></br>
                This project showcases my expertise in web development and
                design, delivering a visually appealing and user-friendly
                website that effectively promotes a coffee brand's unique
                identity.
              </h5>
            </div>
            <div id="works-btns">
              
                <a
                  href="https://coffee-beans.netlify.app/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Site | {'<>'}
                </a>
              

              
                <a
                  href="https://github.com/MRCODES/coffee"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Code{'<>'}
                </a>
              
            </div>
          </article>

          {/*BAKERY*/}

          <article class="eight_box">
            <div id="works-described">
              <h3>Bakery Website</h3>

              <ul>
                <li>HTML</li>
                <li>CSS</li>
                <li>Javascrip</li>
                <li>JQuery</li>
              </ul>

              <b>
                <p>Summary</p>
              </b>

              <br></br>

              <h5>
                I designed a visually appealing bakery website using HTML, CSS,
                JavaScript, and jQuery. The website has an attractive interface
                that captures the essence of a bakery and provides an enjoyable
                experience for users.
              </h5>
            </div>

            <div id="works-btns">
              
                <a
                  href="https://small-bakery.netlify.app"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Site | {'<>'}
                </a>
              

              
                <a
                  href="https://github.com/MRCODES/Anna-s-Bakery"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Code{'<>'}
                </a>
              
            </div>
          </article>
        </div>
        <h2>Adobe Designs</h2>
        <div id="designs">
          {/*ICE CREAM */}

          <article class="nine_box">
            <div id="works-described">
              <h3>Branding #1</h3>

              <h5>
                I creatively designed and illustrated a captivating logo for an
                ice cream shop.
                <br></br>
                <br></br>
                This project showcases my artistic skills and ability to create
                visually striking and memorable logo designs that effectively
                represent the brand identity of the ice cream shop.
              </h5>
            </div>
          </article>

          {/*TOWER*/}

          <article class="ten_box">
            <div id="works-described">
              <h3>Branding #2</h3>

              <h5>
                I created this logo for a tower company concept using only
                letters, showcasing my ability to create something challenging
                and unique for a brand.
              </h5>
            </div>
          </article>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;

import videoMenu from "./vids/Red-Glowing-Circle.mp4";
import off from "./img/off.png";
import on from "./img/on.png";
import { useState } from "react";

import ToggleButtonBox from './ToggleButtonBox';


const Nav = () => {


//******USE STATE #1******

//Menu State: Change button to on and off.

const [Btn, setBtn ] = useState();

  
//Function Created with useState inside
const changeButton = ()=> {

setBtn(current => !current);

}
  


  
//******USE STATE #3: MENU******
  
// Menu State: Toggle UseState for Menu Toggle
const [setToggle, setToggleState] = useState(false);

//Function Created with useState inside
const toggleMenuFunction = () => {

setToggleState(current => !current);  //Current state of useState set to "not current!" Or "Not false!" or "True" 

  }

  

  //******USE STATE #3******

//State: Make toggle disappear
  








return (  

//NAVIGATION CODE
  
<nav> 


        {/* Toggled button on Click*/}


        <div className="toggle--button-box" 

      onClick={() => { toggleMenuFunction(); }}

  
    >

              <img 

              src={!Btn ? off: on } 
        
              alt='bulb-off' 
              onClick={changeButton}  
              width="60px"
              

              />


    </div>
    








{/* Toggled Menu on Click*/}

        { setToggle && 


          
          <div class="links"> {/*MENU LINKS*/}

            <a href="#about-scroll" id="summary">
              <p>Summary</p>
            </a>

            <a href="#portfolio-scroll" id="projects">
              <p>Projects</p>
            </a>

             <a href="#skills-scroll" id="skills">
              <p>Skills</p>
            </a>

            <a href="#footer-scroll" id="contact">
              <p>Contact</p>
            </a>
            


            
          {/* Menu Circle Laser Video*/}

           <video autoPlay muted loop id="video-menu">
            <source src={videoMenu} type="video/mp4" />
            </video>

          </div>

    }
    


    <div>
      <ToggleButtonBox />
    </div>


        </nav>




    );
}
 
export default Nav;

/*
Retired: 
window.addEventListener('scroll', function () {
  
  var toggleButtonBox = document.querySelector('nav');
  if (window.pageYOffset > 100) {
    toggleButtonBox.style.display = 'none';
  } else {
    toggleButtonBox.style.display = 'block';
  }

});
*/

const ToggleButtonBox = () => {
  
  window.addEventListener('scroll', function () {
  
  var toggleButtonBox = document.querySelector('nav');
  if (window.pageYOffset > 100) {
    toggleButtonBox.style.display = 'none';
  } else {
    toggleButtonBox.style.display = 'block';
  }

});
 
};

export default ToggleButtonBox;

/** @format */


import Audio from './Audio';
import Header from './Header';
import About from './About';
import Skills from './Skills';
import Portfolio from './Portfolio';
import Footer from './Footer';





/*import Button from './components/Button';*/


import "./fonts/Monoton-Regular.ttf";




/*import './img/stars.png;'*/



function App() {
  return (



    <body>
    
      
      <Audio />
      
      <Header />

      <About />
      
      <Skills />
      
      <Portfolio />
      
      <Footer />

    </body>
  );
}

export default App;
